import {io} from "socket.io-client";
import OnlineOrders from "./pages/OnlieOrders/OnlineOrders";
import {CoreUIProvider} from "@dladio/core-ui";
import ConfirmDialog from "./components/ConfirmDialog/ConfirmDialog";

export const socket = io('wss://core.teadrop.us')

function App() {
    return (
        <CoreUIProvider>
            <OnlineOrders/>
            <ConfirmDialog />
        </CoreUIProvider>
    );
}

export default App;
