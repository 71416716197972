const ORDER_TABLE_CONFIG = {
    header_fields: [
        {
            label: 'Order No',
            key: "DocNum",
            min_width: 100,
            max_width: 100
        },
        {
            label: 'Business Partner',
            key: "CardName",
            min_width: 150,
            max_width: 200
        },
        {
            label: 'Notes',
            key: "Comments",
            min_width: 50,
            max_width: 50
        },
        {
            label: 'Order Ref',
            key: "NumAtCard",
            min_width: 80,
            max_width: 150
        },
        {
            label: 'Order Date',
            key: "DocDate",
            min_width: 80,
            max_width: 150
        },
        {
            label: 'Order Value',
            key: "FormattedDocTotal",
            min_width: 100,
            max_width: 150
        },
        {
            label: 'Mode of Delivery',
            key: "ModeOfDelivery",
            min_width: 150,
            max_width: 200
        },
        {
            label: 'Payment Term',
            key: "PymntGroup",
            min_width: 100,
            max_width: 150
        },
        {
            label: 'Status',
            key: "DocStatus",
            min_width: 50,
            max_width: 80
        },
        {
            label: 'Pick Status',
            key: "PickableStatus",
            min_width: 100,
            max_width: 150
        }
    ]
}

const PICK_STATUS_COLOR_CONFIG = {
    "Not Pickable" : "bg-red-500",
    "Picked": "bg-green-500",
    "Partially Pickable": "bg-[#0dcaf0]",
    "Fully Pickable":"bg-blue-500"
}

export {
    ORDER_TABLE_CONFIG,
    PICK_STATUS_COLOR_CONFIG
}