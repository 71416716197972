import _ from "lodash";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useLocalStore } from "@dladio/hooks";
import { dispatchAction } from "@dladio/app-bridge";
import { generateInvoice } from "../../../../actions/action";
import { showConfirmDialog } from "../../../../store/reducers/confirm-dialog";
import { METHOD_API, BASE_URL } from "../../../../const/method.const";
import { onlineOrderSelection } from "../../../../service/table-selection.service";
import { DefaultCommandBar, TitleBar } from "@dladio/core-ui";
import { LOCAL_STORE_KEYS, PAGE_STATES } from "../../../../const/local-store.const";
import { middleErrorToast, middleSuccessToast, middleWarringToast } from "../../../../service/toast.service";

const Header = () => {
    const { model: PSModel, setStore: setPSModel } = useLocalStore(LOCAL_STORE_KEYS.PAGE_STATES)
    const dispatch = useDispatch()

    return (
        <>
            <TitleBar title="Open Online Sales Orders" subTitle={'Pick & Pack management'} />
            <DefaultCommandBar actions={[
                {
                    key: 1,
                    text: 'Back',
                    iconProps: { iconName: 'Back' },
                    onClick: () => {
                        dispatchAction({
                            action: 'navigate',
                            payload: -1
                        }, "https://helios.dlad.io/")
                    }
                },
                {
                    key: 2,
                    text: 'Generate Invoice',
                    iconProps: { iconName: 'AddToShoppingList' },
                    onClick: () => {
                        dispatch(
                            showConfirmDialog({
                                title: "Confirmation Required!",
                                subtitle: "Are you sure you want to continue with the actions",
                                onConfirm: () => {
                                    if (_.isArray(onlineOrderSelection?.getSelection()) && onlineOrderSelection?.getSelection()?.length > 0) {

                                        dispatch(generateInvoice(METHOD_API.GENERATE_INVOICE_LIST, {
                                            orders: onlineOrderSelection.getSelection()?.map((line: any) => line?.DocEntry)
                                        }, { forceRefetch: true }) as any)
                                            .then((data: any) => middleSuccessToast("Process Enqueued"))
                                            .catch((e: any) => middleErrorToast("Cannot Process"))

                                    } else {
                                        middleWarringToast("Please select at least one item")
                                    }
                                }
                            })
                        );
                    }
                },
                {
                    key: 3,
                    text: 'Print Bulk Picking List',
                    iconProps: { iconName: 'Print' },
                    onClick: () => {
                        dispatch(
                            showConfirmDialog({
                                title: "Confirmation Required!",
                                subtitle: "Are you sure you want to continue with the actions",
                                onConfirm: () => {
                                    setPSModel(PAGE_STATES.LOADING, true)
                                    axios.post(`${BASE_URL}/method/${METHOD_API.PRINT_BULK_PICKING_LIST}`, {
                                        orders: onlineOrderSelection.getSelection()?.map((line: any) => line?.DocEntry)
                                    }, {
                                        responseType: "blob",
                                        withCredentials: true
                                    })
                                        .then(res => {
                                            const blobURL = URL.createObjectURL(res.data);

                                            const iframe: any = document.createElement('iframe');
                                            document.body.appendChild(iframe);

                                            iframe.style.display = 'none';
                                            iframe.src = blobURL;

                                            iframe.onload = () => {
                                                setTimeout(() => {
                                                    iframe.focus();
                                                    // @ts-ignore
                                                    iframe.contentWindow.print();

                                                }, 1);
                                            }
                                            setPSModel(PAGE_STATES.LOADING, false)
                                        })
                                        .catch(err => {
                                            console.error(err)
                                            setPSModel(PAGE_STATES.LOADING, false)
                                        })
                                }
                            })
                        );
                    }
                }
            ]}
            />
        </>
    )
}

export default Header