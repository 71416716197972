import {Text} from '@fluentui/react'
import {dispatchAction} from "@dladio/app-bridge";

interface IProps {
    href: string,
    children: any
}

const Link = ({href, children}: IProps) => {

    const goTo = () => {
        dispatchAction({
            action: 'navigate',
            payload: href
        },"https://helios.dlad.io/")
    }

    return (
        <div onClick={goTo}>
            <Text className={'text-blue-500 underline cursor-pointer'} variant={'small'}>{children}</Text>
        </div>
    )
}

export default Link
