import _ from "lodash";
import Header from "./components/Header/Header";
import Loading from "../../components/Loading/Loading";
import OrderTable from "./components/OrderTable/OrderTable";
import { useEffect } from "react";
import { useLocalStore } from "@dladio/hooks";
import { ORDER_TABLE_CONFIG } from "./components/OrderTable/const";
import { SERVICE, useDerive } from "@dladio/service";
import { LOCAL_STORE_KEYS, PAGE_STATES } from "../../const/local-store.const";

const OnlineOrders = () => {
    const { model: PSModel, setStore: setPSModel } = useLocalStore(LOCAL_STORE_KEYS.PAGE_STATES)

    const { value, isFetching } = useDerive('exec', {
        options: {
            method: SERVICE.EXEC_QUERY_METHOD,
            body: { name: '634f877580' }
        },
        path: 'message'
    })

    const isLoading = _.get(PSModel, PAGE_STATES.LOADING)

    useEffect(() => {
        if (isFetching) setPSModel(PAGE_STATES.LOADING, true)
        else setPSModel(PAGE_STATES.LOADING, false)
    }, [isFetching])

    return (
        <>
            <Header />
            {isLoading ? <Loading /> : <>
                <OrderTable config={ORDER_TABLE_CONFIG} model={value ?? []} />
            </>}
        </>
    )
}

export default OnlineOrders