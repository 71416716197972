import _ from 'lodash'
import Link from "../../../../components/Link/Link";
import {Box} from "@dladio/core-ui";
import {useAutoFit} from "@dladio/hooks";
import {multiSearch} from "@dladio/utils";
import {onlineOrderSelection} from "../../../../service/table-selection.service";
import {PICK_STATUS_COLOR_CONFIG} from "./const";
import React, {useEffect, useState} from "react";
import {
    DetailsHeader,
    DetailsList,
    DetailsListLayoutMode,
    IColumn, Icon,
    IDetailsHeaderProps,
    IDetailsListStyles,
    SearchBox,
    SelectionMode,
    Text,
} from "@fluentui/react";

interface IProps {
    config: any,
    model: any
}

const OrderTable = ({config, model}: IProps) => {
    const [lines, setLines] = useState<any>([])
    const [columns, setColumns] = useState<any>([])
    const [ref, setAutoFit] = useAutoFit()

    useEffect(() => {
        setTimeout(() => {
            setAutoFit()
        }, 400)
    }, [])

    useEffect(() => {
        setLines(model)
    }, [model])

    useEffect(() => {
        generateColumns()
    }, [model, config])

    const quantityFocusHandler = () => {

    }

    const onRenderItemColumn = (model: any, index: any, column: any) => {
        const fieldContent = _.get(model, column.fieldName);

        if (column.fieldName === 'DocNum') {
            return (
                <Link href={`/app/page/51d1ebb719/?id=${_.get(model, 'DocEntry')}`}>
                    {fieldContent}
                </Link>
            )

        } else if (column.fieldName === 'DocStatus') {
            return (
                <Text
                    variant={'xSmall'}
                    className={`${fieldContent === 'OPEN' ? 'bg-red-600' : 'bg-green-500'} text-white mr-2 px-2.5 py-0.5 rounded`}>
                    {fieldContent}
                </Text>
            )

        } else if (column.fieldName === 'PickableStatus') {
            return (
                <Text
                    variant={'xSmall'}
                    className={`${_.get(PICK_STATUS_COLOR_CONFIG, fieldContent)} text-white mr-2 px-2.5 py-0.5 rounded`}>
                    {fieldContent}
                </Text>
            )
        } else if (column.fieldName === 'Comments') {
            const CommentIcon = () => <Icon iconName="FileComment"/>
            return (
                <>
                    {fieldContent?.length > 0 ? <CommentIcon /> : ''}
                </>
            )
        }

        return fieldContent
    }

    const generateColumns = () => {

        function _onColumnClick(event: React.MouseEvent<HTMLElement>, column: IColumn) {
            let isSortedDescending = column.isSortedDescending;
            if (column.isSorted) {
                isSortedDescending = !isSortedDescending;
            }

            let sortedItems = _copyAndSort(model, column.fieldName!, isSortedDescending);

            let columnList: any = columns.map((col: any) => {
                col.isSorted = col.key === column.key;

                if (col.isSorted) {
                    col.isSortedDescending = isSortedDescending;
                }

                return col;
            })

            setLines(sortedItems)
            setColumns(columnList)
        }

        let columns: IColumn[] = config.header_fields.map((line: any, index: any) => ({
                key: index,
                name: line.label,
                fieldName: line.key,
                page: line.page,
                dataType: line?.type === 'divider' ? null : line.data_type,
                meta: line?.field_meta,
                isResizable: true,
                minWidth: line?.min_width,
                maxWidth: line?.max_width,
                isSortedDescending: false,
                isSorted: false,
                styles: {root: {backgroundColor: '#f0f0f0'}},
                onColumnClick: _onColumnClick
            })
        )
        setColumns(columns)
    }

    function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;
        return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }

    const _onRenderDetailHeader: any = (detailsHeaderProps: IDetailsHeaderProps) => {
        if (detailsHeaderProps) {
            return (
                <DetailsHeader
                    {...detailsHeaderProps}
                />
            )
        }
        return null
    }
    const searchTextChangeHandler = (e: any, text: any) => {
        const filteredLines = text && model.length > 0 ? multiSearch(model, text) : model
        setLines(filteredLines)
    }

    let className = "overflow-auto h-lg"

    return (
        <Box title={`Orders (${model?.length ?? 0})`}>
            <div className={'flex flex-wrap'}>
                <SearchBox onChange={searchTextChangeHandler} className="w-[280px] m-2 rounded-2" placeholder="Search"/>
            </div>

            <div ref={ref} className={className}>
                <DetailsList
                    items={lines}
                    columns={columns}
                    selection={onlineOrderSelection}
                    compact={true}
                    styles={gridStyles}
                    onActiveItemChanged={quantityFocusHandler}
                    setKey="none"
                    onRenderItemColumn={onRenderItemColumn}
                    onRenderDetailsHeader={_onRenderDetailHeader}
                    isHeaderVisible={true}
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionMode={SelectionMode.multiple}
                    selectionPreservedOnEmptyClick={true}
                />
            </div>
        </Box>
    )
}
const gridStyles: Partial<IDetailsListStyles> = {
    root: {
        overflowX: 'scroll',
        selectors: {
            '& [role=grid]': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                // height: ,
            },
        }
    },
    headerWrapper: {
        flex: '0 0 auto'
    },
    contentWrapper: {
        flex: '1 1 auto',
        overflowY: 'auto',
        overflowX: 'hidden',
    }
};

export default React.memo(OrderTable)