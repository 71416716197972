import {erpMethodApi} from "@dladio/service";
import {extendedApiSlice} from "../service/custom-api.service";

export const execMethod = (method: any, body: any, options = {}) => {
    return async (dispatch: any) => {
        const {data, error} = await dispatch(erpMethodApi.endpoints.execMethodApi.initiate({
            method: method,
            body: body,
        }, options))

        if (!error) return data
        else throw error
    }
}

export const generateInvoice = (method: any, body: any, options = {}) => {
    return async (dispatch: any) => {
        const {data, error} = await dispatch(extendedApiSlice.endpoints.generateInvoice.initiate({
            method: method,
            body: body,
        }, options))

        if (!error) return data
        else throw error
    }
}

export const execMutationMethod = (method: any, body: any) => {
    return async (dispatch: any) => {
        const {data, error} = await dispatch(erpMethodApi.endpoints.execMutationApi.initiate({
            method: method,
            body: body,
        }))

        if (!error) return data
        else throw error
    }
}